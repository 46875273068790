import React from "react";
import { Formik } from 'formik';
import ProductForm from "./ProductForm";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import PropTypes from "prop-types";

const Edit = ({ product }) => {
  const isNewRecord = !product.id;
  let path;
  if (isNewRecord) {
    path = "/products.json";
  } else {
    path = `/products/${product.id}.json`;
  }

  return (
    <Formik
      initialValues={product}
      onSubmit={handleSubmitResource({path, isNewRecord})}
    >
      {({ values, errors}) => (
        <ProductForm values={values} errors={errors} />
      )}
    </Formik>
  );
};

Edit.propTypes = {
  product: PropTypes.object,
};

export default Edit;
