import React from "react";
import PropTypes from "prop-types";
import {
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import { Formik } from "formik";
import ServicingForm from "./ServicingForm";

const ServicingEditModal = ({servicing, servicing_types, setModalVisible}) => {
  const isNewRecord = !servicing.id;
  let path;
  if (isNewRecord) {
    path = "servicings.json";
  } else {
    path = `servicings/${servicing.id}.json`;
  }

  return (
    <EuiModal onClose={() => setModalVisible(false)}>
      <EuiModalHeader>
        <EuiModalHeaderTitle><h1>Tytuł</h1></EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <Formik
          initialValues={servicing}
          onSubmit={handleSubmitResource({path, isNewRecord})}
        >
          {({ values, errors}) => (
            <ServicingForm
              values={values}
              errors={errors}
              servicing_types={servicing_types}
            />
          )}
        </Formik>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => setModalVisible(false)}>
          Anuluj
        </EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};

ServicingEditModal.propTypes = {
  servicing: PropTypes.object,
  servicing_types: PropTypes.array,
  setModalVisible: PropTypes.func
};

export default ServicingEditModal;
