import React from "react";
import { Form, Field } from 'formik';
import { EuiButton, EuiFlexGroup, EuiForm, EuiFormRow, EuiFlexItem } from "@elastic/eui";
import StringInput from "../fields/StringInput";
import PropTypes from "prop-types";
import Checkbox from "../fields/Checkbox";
import NumberInput from "../fields/NumberInput";
import kind from "./kind"
import SelectInput from "../fields/SelectInput";

const CANCEL_PATH="/customers";

// eslint-disable-next-line no-unused-vars
const CustomerForm = ({ values, errors }) => {
  const isInvalid = errors.base && errors.base.length > 0;

  return (
    <Form>
      <EuiForm
        isInvalid={isInvalid}
        error={errors.base}
      >
        <Field
          name="name"
          placeholder="nazwa klienta"
          label="Nazwa"
          component={StringInput}
        />

        <Field
          name="street"
          placeholder="ulica"
          label="Ulica"
          component={StringInput}
        />

        <Field
          name="number"
          placeholder="numer domu"
          label="Numer domu/lokalu"
          component={StringInput}
        />

        <Field
          name="code"
          placeholder="kod"
          label="Kod pocztowy"
          component={StringInput}
        />

        <Field
          name="city"
          placeholder="miejscowość"
          label="Miasto"
          component={StringInput}
        />

        <Field
          name="nip"
          placeholder="NIP"
          label="NIP"
          component={StringInput}
        />

        <Field
          name="address"
          placeholder="adres dostawy"
          label="Adres dostawy"
          component={StringInput}
        />

        <Field
          name="kind"
          label="Rodzaj"
          component={SelectInput}
          options={kind}
        />

        <Field
          name="notification_amount"
          label="Alarm poniżej ilości towaru"
          component={NumberInput}
        />

        <Field
          id="active"
          name="active"
          label="Aktywny?"
          component={Checkbox}
        />

        <EuiFormRow>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton type="submit" fill disabled={isInvalid}>
                Submit
              </EuiButton>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton href={CANCEL_PATH}>
                Anuluj
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiForm>
    </Form>
  );
};

CustomerForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
};

export default CustomerForm;
