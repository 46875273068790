import React, {useState} from "react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiInMemoryTable, EuiText } from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import PropTypes from "prop-types";
import kind from "./kind"

const CUSTOMERS_PATH = "/customers/:id";
const NEW_CUSTOMER_PATH = "/customers/new";

const Index = ({customers}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [path, setPath] = useState("");

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam klienta z bazy danych."
    setModalVisible={setModalVisible}
  />;

  const destroyCustomer = (customer) => {
    setPath(CUSTOMERS_PATH.replace(':id', `${customer.id}.json`));
    setModalVisible(true);
  };

  const columns = [
    {
      field: "name",
      name: "Nazwa",
      sortable: true,
      truncateText: false,
    },
    {
      field: "street",
      name: "Ulica",
      sortable: false,
      truncateText: false,
    },
    {
      field: "number",
      name: "Numer domu/lokalu",
      sortable: false,
      truncateText: false,
    },
    {
      field: "city",
      name: "Miasto",
      sortable: true,
      truncateText: false,
    },
    {
      field: "address",
      name: "Adres dostawy",
      sortable: false,
      truncateText: false,
    },
    {
      field: "kind",
      name: "Rodzaj",
      sortable: true,
      truncateText: true,
      render: (id) => {
        const kindLocal = kind.find(k => k.value == id);
        return <EuiText>{kindLocal.text}</EuiText>;
      },
    },
    {
      field: "active",
      name: "Aktywny?",
      dataType: "boolean",
      render: (active) => {
        const color = active ? "success" : "danger";
        return <EuiHealth color={color} />;
      },
      sortable: true,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja danych klienta",
          icon: "pencil",
          type: "icon",
          onClick: (customer) => {
            window.location.href = CUSTOMERS_PATH.replace(":id", `${customer.id}/edit`);
          },
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie klienta",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyCustomer,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  };

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
    filters: [
      {
        type: 'is',
        field: 'active',
        name: 'Aktywny',
        negatedName: 'Nieaktywny',
      },
      {
        type: "field_value_selection",
        field: "kind",
        name: "Rodzaj",
        multiSelect: false,
        options: kind.map((k) => ({
          value: k.value,
          name: k.text,
          view: `${k.text}`,
        })),
      },
    ],
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton href={NEW_CUSTOMER_PATH}>Nowy</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiInMemoryTable
        items={customers}
        columns={columns}
        pagination={true}
        sorting={sorting}
        search={search}
      />

      {modalVisible && deleteModal}
    </>
  );
};

Index.propTypes = {
  customers: PropTypes.array
};

export default Index;
