import React, { useState } from "react";
import { EuiButton, EuiInMemoryTable, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import PropTypes from "prop-types";
import ServicingEditModal from "./ServicingEditModal";
import DeleteModal from "../DeleteModal";

const servicingBlank = {
  date_done: "",
  date_next: "",
  date_alarm: "",
  description: "",
};

const DELETE_SERVICING_PATH = "servicings/:id";

const ServicingsEdit = ({servicings, servicing_types}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [path, setPath] = useState("");
  const [servicing, setServicing] = useState(servicingBlank);

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam dane przeglądu technicznego z bazy danych."
    setModalVisible={setDeleteModalVisible}
  />;

  const destroyServicing = (serv) => {
    setPath(DELETE_SERVICING_PATH.replace(':id', `${serv.id}.json`));
    setDeleteModalVisible(true);
  };

  const editServicing = (serv) => {
    setServicing(serv);
    setModalVisible(true);
  };

  const columns = [
    {
      field: "servicing_type_id",
      name: "Typ",
      sortable: true,
      truncateText: false,
      render: (id) => {
        const type = servicing_types.find(st => st.value === id);
        return <EuiText>{type.text}</EuiText>;
      },
    },
    {
      field: "date_done",
      name: "Data wpisu",
      sortable: true,
      truncateText: false,
    },
    {
      field: "date_next",
      name: "Data następnego",
      sortable: true,
      truncateText: false,
    },
    {
      field: "date_alarm",
      name: "Data alarmu",
      sortable: true,
      truncateText: false,
    },
    {
      field: "description",
      name: "Uwagi",
      sortable: true,
      truncateText: true,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja przeglądu",
          icon: "pencil",
          type: "icon",
          onClick: editServicing,
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie kierowcy",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyServicing,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'date_alarm',
      direction: 'asc',
    },
  };

  return (
    <>
      <EuiTitle size="s">
        <h4>Przeglądy</h4>
      </EuiTitle>

      <EuiSpacer />

      <EuiText>
        <EuiButton onClick={() => setModalVisible(true)}>Nowy</EuiButton>
      </EuiText>

      <EuiInMemoryTable
        items={servicings}
        columns={columns}
        pagination={true}
        sorting={sorting}
      />

      {
        modalVisible
        &&
        <ServicingEditModal
          servicing={servicing}
          servicing_types={servicing_types}
          setModalVisible={setModalVisible}
        />
      }

      {deleteModalVisible && deleteModal}
    </>
  );
};

ServicingsEdit.propTypes = {
  servicings: PropTypes.array,
  servicing_types: PropTypes.array
};

export default ServicingsEdit;
