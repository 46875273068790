import React from "react";
import { Formik } from 'formik';
import CustomerForm from "./CustomerForm";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import PropTypes from "prop-types";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import EstatesEdit from "./EstatesEdit";

const Edit = ({ customer, products }) => {
  const isNewRecord = !customer.id;
  let path;
  if (isNewRecord) {
    path = "/customers.json";
  } else {
    path = `/customers/${customer.id}.json`;
  }

  const estates = <EstatesEdit customer_estates={customer.customer_estates} products={products} />;

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <Formik
          initialValues={customer}
          onSubmit={handleSubmitResource({ path, isNewRecord })}
        >
          {({ values, errors }) => (
            <CustomerForm values={values} errors={errors} />
          )}
        </Formik>
      </EuiFlexItem>

      <EuiFlexItem>
        {!isNewRecord && estates}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

Edit.propTypes = {
  visit: PropTypes.object,
  products: PropTypes.array,
};

export default Edit;
