import React, { useState } from "react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiInMemoryTable } from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import PropTypes from "prop-types";

const CARS_PATH = "/cars/:id";
const NEW_CAR_PATH = "/cars/new";

const Index = ({ cars }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [path, setPath] = useState("");

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam dane pojazdu z bazy danych."
    setModalVisible={setModalVisible}
  />;

  const destroyCar = (driver) => {
    setPath(CARS_PATH.replace(':id', `${driver.id}.json`));
    setModalVisible(true);
  };

  const columns = [
    {
      field: "id",
      name: "ID",
      sortable: true,
      truncateText: true,
    },
    {
      field: "name",
      name: "Nazwa",
      sortable: true,
      truncateText: true,
    },
    {
      field: "official_number",
      name: "Nr rejestracyjny",
      sortable: true,
      truncateText: true,
    },
    {
      field: "active",
      name: "Aktywny?",
      dataType: "boolean",
      render: (active) => {
        const color = active ? "success" : "danger";
        return <EuiHealth color={color} />;
      },
      sortable: true,
    },
    {
      field: "Przegląd techniczny",
      name: "Przegląd techniczny",
      sortable: false,
      truncateText: false,
    },
    {
      field: "Ubezpieczenie",
      name: "Ubezpieczenie",
      sortable: false,
      truncateText: false,
    },
    {
      field: "UDT",
      name: "UDT",
      sortable: false,
      truncateText: false,
    },
    {
      field: "TDT",
      name: "TDT",
      sortable: false,
      truncateText: false,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja danych pojazdu",
          icon: "pencil",
          type: "icon",
          onClick: (driver) => {
            window.location.href = CARS_PATH.replace(":id", `${driver.id}/edit`);
          },
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie pojazdu",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyCar,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton href={NEW_CAR_PATH}>Nowy</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiInMemoryTable
        items={cars}
        columns={columns}
        pagination={true}
        sorting={sorting}
      />

      {modalVisible && deleteModal}
    </>
  );
};

Index.propTypes = {
  cars: PropTypes.array
};

export default Index;
