import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EuiGlobalToastList } from "@elastic/eui";

const Notices = ({ notice, error }) => {
  const [toasts, setToasts] = useState([]);

  const toastNotice =  {
    id: "1",
    title: "Info",
    color: 'success',
    iconType: 'check',
    text: <p>{notice}</p>,
  };

  const toastError =  {
    id: "2",
    title: "Uwaga!",
    color: 'danger',
    iconType: 'alert',
    text: <p>{error}</p>,
  };

  const removeToast = (removedToast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  useEffect(() => {
    if (notice && notice.length > 0) {
      setToasts([...toasts, toastNotice]);
    } else if (error && error.length > 0) {
      setToasts([...toasts, toastError]);
    }
  },[notice, error])

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
    />
  );
};

Notices.propTypes= {
  notice: PropTypes.string,
  error: PropTypes.string,
}

export default Notices;
