import React from "react";
import { Form, Field, FieldArray } from 'formik';
import {
  EuiButton,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiFlexItem,
} from "@elastic/eui";
import StringInput from "../fields/StringInput";
import PropTypes from "prop-types";
import Autocomplete from "../fields/Autocomplete";
import DateInput from "../fields/DateInput";
import SalesForm from "./SalesForm";
import _ from "lodash";
import { textToLabels } from "../fields/tsFormSubmitHelpers";

const CANCEL_PATH = "/visits";

const VisitForm = ({ values, errors, drivers, customers, products }) => {
  const isInvalid = !_.isEmpty(errors);

  return (
    <Form>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiForm
            isInvalid={isInvalid}
            error={Object.keys(errors)}
          >
            <Field
              name="date_of_v"
              label="Data wizyty"
              component={DateInput}
            />

            <Field
              name="driver_id"
              label="Kierowca"
              component={Autocomplete}
              options={textToLabels(drivers)}
              placeholder="Wybierz, lub wpisz nazwisko kierowcy"
            />

            <Field
              name="customer_id"
              label="Klient"
              component={Autocomplete}
              options={textToLabels(customers)}
              placeholder="Wybierz, lub wpisz nazwę klienta"
            />

            <Field
              name="description"
              placeholder="uwagi"
              label="Uwagi"
              component={StringInput}
            />

            <EuiFormRow>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButton type="submit" fill disabled={isInvalid}>
                    Zapisz
                  </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiButton href={CANCEL_PATH}>
                    Anuluj
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiForm>
        </EuiFlexItem>

        <EuiFlexItem grow>
          <FieldArray name={"sales_attributes"}>
            {(arrayHelpers) => (
              <SalesForm
                arrayHelpers={arrayHelpers}
                sales_attributes={values.sales_attributes}
                errors={errors}
                products={products}
              />
            )}
          </FieldArray>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Form>
  );
};

VisitForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  drivers: PropTypes.array,
  customers: PropTypes.array,
  products: PropTypes.array,
};

export default VisitForm;
