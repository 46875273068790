// Odpowiednik dla KIND = { 1 => 'indywidualny', 2 => 'hurt', 3 => 'odbiór własny' }
// w modelu Customer
// Zamieszczone tu, gdyż prawdopodobnie się to nie zmieni, a ograniczy niepotrzebnego developmentu.
const kind = [
  {
    value: "1",
    text: "indywidualny",
  },
  {
    value: "2",
    text: "hurt",
  },
  {
    value: "3",
    text: "odbiór własny",
  },
];

export default kind;
