import _ from "lodash";
import axios from "axios";

const UTC_OFFSET = -2 * 60;

const submitFormWithPut = (
  values,
  path
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  return new Promise((resolve, reject) => {
    axios
      .put(path, {
        ...values,
      }, {headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }})
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response.data.errors);
      });
  });
};

const submitFormWithPost = (
  values,
  path
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  return new Promise((resolve, reject) => {
    axios
      .post(path, {
        ...values,
      }, {headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }})
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response.data.errors);
      });
  });
};

const submitDeleteForm = (
  values,
  path
) => {
  return new Promise((resolve, reject) => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    axios
      .delete(path, {
        data: { ...values },
      }, {headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }})
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response.data.errors);
      });
  });
};

const handleSubmitResource = ({
  path,
  isNewRecord,
  omitData,
}) => (
  values,
  { setSubmitting, setErrors }
) => {
  let submitForm;
  if (isNewRecord) {
    submitForm = submitFormWithPost;
  } else {
    submitForm = submitFormWithPut;
  }

  const valuesToSend = omitData ? _.omit(values, omitData) : values;

  submitForm(valuesToSend, path)
    .then((resolve) => {
      if (resolve.url) {
        window.location.href = resolve.url;
      } else {
        console.log(resolve);
        // TODO set submitted values
      }
    })
    .catch((errors) => {
      setErrors(errors);
    });
  setSubmitting(false);
};

const handleDeleteResource = ({ path }) => (
  values,
  { setSubmitting, setErrors }
) => {
  submitDeleteForm(values, path)
    .then((resolve) => {
      if (resolve.url) {
        window.location.href = resolve.url;
      } else {
        console.log(resolve);
      }
    })
    .catch((errors) => {
      setErrors(errors);
    });
  setSubmitting(false);
};

const textToLabels = (ary) => {
  return ary.map(a => {
    return { ...a, label: a.text };
  });
};



export { handleSubmitResource, handleDeleteResource, UTC_OFFSET, textToLabels };
