import React, {useState} from "react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiInMemoryTable } from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import PropTypes from "prop-types";

const PRODUCTS_PATH = "/products/:id";
const NEW_PRODUCT_PATH = "/products/new";

const Index = ({products}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [path, setPath] = useState("");

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam towar z bazy danych."
    setModalVisible={setModalVisible}
  />;

  const destroyProduct = (product) => {
    setPath(PRODUCTS_PATH.replace(':id', `${product.id}.json`));
    setModalVisible(true);
  };

  const columns = [
    {
      field: "id",
      name: "ID",
      sortable: true,
      truncateText: true,
    },
    {
      field: "name",
      name: "Nazwa",
      sortable: true,
      truncateText: true,
    },
    {
      field: "base_price",
      name: "Cena bazowa",
      sortable: true,
      truncateText: false,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja towaru",
          icon: "pencil",
          type: "icon",
          onClick: (product) => {
            window.location.href = PRODUCTS_PATH.replace(":id", `${product.id}/edit`);
          },
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie towaru",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyProduct,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton href={NEW_PRODUCT_PATH}>Nowy</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiInMemoryTable
        items={products}
        columns={columns}
        pagination={true}
        sorting={sorting}
      />

      {modalVisible && deleteModal}
    </>
  );
};

Index.propTypes = {
  products: PropTypes.array
};

export default Index;
