import React from "react";
import PropTypes from "prop-types";
import { EuiHealth, EuiInMemoryTable } from "@elastic/eui";

const Cars = ({ servicings }) => {
  const columns = [
    {
      field: "car_name",
      name: "Nazwa",
      sortable: true,
      truncateText: false,
    },
    {
      field: "car_official_number",
      name: "Nr rejestracyjny",
      sortable: true,
      truncateText: false,
    },
    {
      field: "servicing_type",
      name: "Typ",
      sortable: true,
      truncateText: false,
    },
    {
      field: "date_next",
      name: "Data zapadania",
      sortable: true,
      truncateText: false,
    },
    {
      field: "days_left",
      name: "Pozostało dni",
      sortable: true,
      truncateText: false,
    },
    {
      field: "alarm_type",
      name: "Stres",
      render: (alarm_type) => {
        return <EuiHealth color={alarm_type} />;
      },
      sortable: true,
    },
  ];

  const sorting = {
    sort: {
      field: 'days_left',
      direction: 'asc',
    },
  };

  return (
    <EuiInMemoryTable
      items={servicings}
      columns={columns}
      pagination={true}
      sorting={sorting}
    />
  );
};

Cars.propTypes = {
  servicings: PropTypes.array,
};

export default Cars;
