import React from "react";
import PropTypes from "prop-types";
import { EuiBreadcrumbs } from "@elastic/eui";


/*
Z backendu przychodzi coś w rodzaju:
[
  {
    "name": "pierwszy_path",
    "path": "/notifications/previous_period",
    "current": true
  },
]

i w funkcji trailToBreadcrumbs() przetwarzam to na coś w rodzaju:
[
  {
    text: "pierwszy_path",
    href: "/notifications/previous_period",
    onClick: (e) => {
      e.preventDefault();
    },
    'data-test-subj': "pierwszy_path,
  },
]
 */
const trailToBreadcrumbs = (trail) => {
  if (trail === undefined) {
    return []
  }

  return trail.map(trailItem => {
    return { text: trailItem.name, href: trailItem.path }
  })
};


// eslint-disable-next-line no-unused-vars
const Breadcrumbs = ({ breadcrumb_trail }) => {
  return (
    <EuiBreadcrumbs
      breadcrumbs={trailToBreadcrumbs(breadcrumb_trail)}
      truncate={false}
    />
  );
};

Breadcrumbs.propTypes = {
  breadcrumb_trail: PropTypes.string,
};

export default Breadcrumbs;
