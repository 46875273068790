import React from "react";
import PropTypes from "prop-types";
import {
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiForm,
} from "@elastic/eui";
import { Field } from "formik";
import StringInput from "../fields/StringInput";
import _ from "lodash";
import { errorsContains, validateAmount } from "./errorsHelper";
import SelectInput from "../fields/SelectInput";

const blankSale = {
  product_id: "",
  price: "",
  amount: "",
};

const SalesForm = ({ arrayHelpers, sales_attributes, errors, products }) => {

  const errContSales = errorsContains(errors, "sales");
  const isInvalid = !_.isEmpty(errors) && !!errContSales.toString();

  const saleRow = (sale, id) => {
    const fieldPrefix = `sales_attributes[${id}]`;

    return (
      <EuiFormRow key={id}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <Field
              name={`${fieldPrefix}.product_id`}
              value={sale.product_id}
              validate={validateAmount}
              label="Towar"
              options={products}
              component={SelectInput}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <Field
              name={`${fieldPrefix}.price`}
              value={sale.price}
              validate={validateAmount}
              placeholder="price"
              label="Cena"
              component={StringInput}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <Field
              name={`${fieldPrefix}.amount`}
              value={sale.amount}
              validate={validateAmount}
              placeholder="amount"
              label="Ilość"
              component={StringInput}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div className="eui-alignBottom">&nbsp;</div>
            <EuiButtonIcon
              data-id={id}
              onClick={() => arrayHelpers.remove(id)}
              iconType="minusInCircle"
              iconSize="xl"
              size="m"
              aria-label="Add"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    );
  };

  return (
    <EuiForm
      isInvalid={isInvalid}
      error={errContSales}
    >
      <EuiText>
        <EuiButton onClick={() => arrayHelpers.push(blankSale)}>
          Dodaj towar
        </EuiButton>
      </EuiText>

      <EuiSpacer />

      {sales_attributes.map(saleRow)}
    </EuiForm>
  );
};

SalesForm.propTypes = {
  arrayHelpers: PropTypes.object,
  sales_attributes: PropTypes.array,
  errors: PropTypes.object,
  products: PropTypes.array,
};

export default SalesForm;

