import React from "react";
import { Form, Field } from 'formik';
import { EuiButton, EuiFlexGroup, EuiForm, EuiFormRow, EuiFlexItem } from "@elastic/eui";
import StringInput from "../fields/StringInput";
import Checkbox from "../fields/Checkbox";
import PropTypes from "prop-types";

const CANCEL_PATH="/drivers";

// eslint-disable-next-line no-unused-vars
const DriverForm = ({ values, errors }) => {
  const isInvalid = errors.base && errors.base.length > 0;

  return (
    <Form>
      <EuiForm
        isInvalid={isInvalid}
        error={errors.base}
      >
        <Field
          name="first_name"
          placeholder="imię"
          label="Imię"
          component={StringInput}
        />

        <Field
          name="surname"
          placeholder="nazwisko"
          label="Nazwisko"
          component={StringInput}
        />

        <Field
          id="active"
          name="active"
          label="Aktywny?"
          component={Checkbox}
        />

        <EuiFormRow>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton type="submit" fill disabled={isInvalid}>
                Submit
              </EuiButton>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton href={CANCEL_PATH}>
                Anuluj
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiForm>
    </Form>
  );
};

DriverForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
};

export default DriverForm
