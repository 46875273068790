import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { EuiFormRow, EuiFieldText } from "@elastic/eui";

const StringInput = ({
  field: { name, value, onChange },
  form: { errors, touched },
  label,
  readOnly,
  append,
  helpText,
  placeholder,
  fullWidth,
}) => {
  const fieldErrors = _.get(errors, name);

  // const fieldTouched = _.get(touched, name); // gdy tak było nie pokazywały mi się błędy w sprzedażach
  // const isInvalid = fieldTouched && fieldErrors && fieldErrors.length > 0;
  const isInvalid = fieldErrors && fieldErrors.length > 0;
  return (
    <>
      <EuiFormRow
        label={label}
        fullWidth={fullWidth}
        isInvalid={isInvalid}
        error={fieldErrors}
        helpText={helpText}
      >
        <EuiFieldText
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          readOnly={readOnly}
          append={append}
          placeholder={placeholder}
          fullWidth={fullWidth}
        />
      </EuiFormRow>
    </>
  );
};

export default StringInput;

StringInput.propTypes = {
  // formik
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,

  label: PropTypes.string,
  readOnly: PropTypes.bool,
  append: PropTypes.element,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
};

StringInput.defaultProps = {
  label: null,
  readOnly: false,
  append: null,
  helpText: null,
  placeholder: null,
  fullWidth: false,
};
