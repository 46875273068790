import React, { useState } from "react";
import { EuiComboBox, EuiFormRow } from "@elastic/eui";
import _ from "lodash";
import PropTypes from "prop-types";

const Autocomplete = ({
  field: { name, value },
  form: { errors, setFieldValue },
  label,
  readOnly,
  options,
  helpText,
  placeholder,
}) => {
  const initialValue = value ? options.filter(o => o.value === value) : [];
  const [selectedOptions, setSelected] = useState(initialValue);

  const fieldErrors = _.get(errors, name);
  const isInvalid = typeof fieldErrors !== "undefined";

  const onChange = (opts) => {
    setSelected(opts);
    setFieldValue(name, opts[0].value);
  };

  return (
    <EuiFormRow
      label={label}
      isInvalid={isInvalid}
      error={fieldErrors}
      helpText={helpText}
    >
      <EuiComboBox
        placeholder={placeholder}
        singleSelection={{ asPlainText: true }}
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
        isDisabled={readOnly}
      />
    </EuiFormRow>
  );
};

Autocomplete.propTypes = {
  // formik
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,

  options: PropTypes.array,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Autocomplete;
