import React from "react";
import PropTypes from "prop-types";
import { EuiBasicTable } from "@elastic/eui";

const SalesForIndex = ({ sales }) => {
  const columns = [
    {
      field: "name",
      name: "",
      sortable: true,
      truncateText: false,
    },
    {
      field: "amount",
      name: "",
      sortable: true,
      truncateText: false,
    },
    {
      field: "sum",
      name: "",
      sortable: true,
      truncateText: false,
    },
  ];

  return (
    <EuiBasicTable
      items={sales}
      columns={columns}
      compressed
    />
  );
};

SalesForIndex.propTypes = {
  sales: PropTypes.array,
};

export default SalesForIndex;
