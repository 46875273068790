import React from "react";
import PropTypes from "prop-types";
import {
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import { Formik } from "formik";
import EstateForm from "./EstateForm";

const EstateEditModal = ({estate, products, setModalVisible}) => {
  const isNewRecord = !estate.id;
  let path;
  if (isNewRecord) {
    path = "customer_estates.json";
  } else {
    path = `customer_estates/${estate.id}.json`;
  }

  return (
    <EuiModal onClose={() => setModalVisible(false)}>
      <EuiModalHeader>
        <EuiModalHeaderTitle><h1>Majątek klienta</h1></EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <Formik
          initialValues={estate}
          onSubmit={handleSubmitResource({path, isNewRecord})}
        >
          {({ values, errors}) => (
            <EstateForm
              values={values}
              errors={errors}
              products={products}
            />
          )}
        </Formik>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => setModalVisible(false)}>
          Anuluj
        </EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};

EstateEditModal.propTypes = {
  estate: PropTypes.object,
  products: PropTypes.array,
  setModalVisible: PropTypes.func
};

export default EstateEditModal;
