import React from "react";
import { Formik } from 'formik';
import CarForm from "./CarForm";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import PropTypes from "prop-types";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import ServicingsEdit from "./ServicingsEdit";

const Edit = ({ car, servicing_types }) => {
  const isNewRecord = !car.id;
  let path;
  if (isNewRecord) {
    path = "/cars.json";
  } else {
    path = `/cars/${car.id}.json`;
  }

  const servicings = <ServicingsEdit servicings={car.servicings} servicing_types={servicing_types} />;

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <Formik
          initialValues={car}
          onSubmit={handleSubmitResource({ path, isNewRecord })}
        >
          {({ values, errors }) => (
            <CarForm
              values={values}
              errors={errors}
              servicings={car.servicings}
              servicing_types={servicing_types}
            />
          )}
        </Formik>
      </EuiFlexItem>

      <EuiFlexItem>
        {!isNewRecord && servicings}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

Edit.propTypes = {
  car: PropTypes.object,
  servicing_types: PropTypes.array
};

export default Edit;
