import React from "react";
import { EuiSelect, EuiFormRow, EuiSelectOption } from "@elastic/eui";
import _ from "lodash";
import PropTypes from "prop-types";


const SelectInput = ({
  field: { name, value, onChange },
  form: { errors },
  label,
  readOnly,
  options,
  helpText,
  append,
}) => {
  const fieldErrors = _.get(errors, name);
  const isInvalid = typeof fieldErrors !== "undefined";

  return (
    <EuiFormRow
      label={label}
      isInvalid={isInvalid}
      error={fieldErrors}
      helpText={helpText}
    >
      <EuiSelect
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        disabled={readOnly}
        append={append}
        hasNoInitialSelection
      />
    </EuiFormRow>
  );
};

SelectInput.propTypes = {
  // formik
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,

  options: PropTypes.array,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  fullWidth: PropTypes.bool,
  append: PropTypes.object,
};

export default SelectInput;
