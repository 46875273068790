import React, { useState } from "react";
import {
  EuiButton,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiFormRow,
  EuiForm,
  EuiSpacer,
  EuiText, EuiTitle,
} from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import PropTypes from "prop-types";
import { UTC_OFFSET } from "../fields/tsFormSubmitHelpers";
import moment from "moment";
import SalesForIndex from "./SalesForIndex";

const VISITS_PATH = "/visits/:id";
const NEW_VISIT_PATH = "/visits/new";

const Index = ({ visits, startDate, endDate, drivers, customers }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [path, setPath] = useState("");
  const [dateFrom, setDateFrom] = useState(moment(startDate));
  const [dateTo, setDateTo] = useState(moment(endDate));

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam wizytę z bazy danych."
    setModalVisible={setModalVisible}
  />;

  const destroyCustomer = (visit) => {
    setPath(VISITS_PATH.replace(':id', `${visit.id}.json`));
    setModalVisible(true);
  };

  const columns = [
    {
      field: "date_of_v",
      name: "Data",
      sortable: true,
      truncateText: false,
      width: "7%",
    },
    {
      field: "driver",
      name: "Kierowca",
      sortable: true,
      truncateText: true,
      width: "10%",
    },
    {
      field: "customer",
      name: "Klient",
      sortable: true,
      truncateText: false,
    },
    {
      field: "customer_address",
      name: "Adres",
      sortable: true,
      truncateText: false,
    },
    {
      field: "description",
      name: "Uwagi",
      sortable: false,
      truncateText: true,
      width: "8%",
    },
    {
      field: "sales",
      name: (
        <EuiText align="center" size="xs" grow>
          <div><h5>Sprzedaż</h5></div>
          <div>
            <h5>
              towar
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              -
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              ilość
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              -
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              razem zł
            </h5>
          </div>
        </EuiText>
      ),
      sortable: false,
      truncateText: false,
      render: (sales) => {
        let component = <EuiText>brak sprzedaży</EuiText>;
        if (sales.length > 0) {
          component = <SalesForIndex sales={sales} />;
        }
        return component;
      }
    },
    {
      name: "Edycja",
      width: "6%",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja danych klienta",
          icon: "pencil",
          type: "icon",
          onClick: (visit) => {
            window.location.href = VISITS_PATH.replace(":id", `${visit.id}/edit`);
          },
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie klienta",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyCustomer,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'date_of_v',
      direction: 'desc',
    },
  };

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
    filters: [
      {
        type: "field_value_selection",
        field: "driver",
        name: "Kierowca",
        multiSelect: false,
        options: drivers.map((driver) => ({
          value: driver.text,
          name: driver.text,
          view: driver.text,
        })),
      },
      {
        type: "field_value_selection",
        field: "customer",
        name: "Klient",
        multiSelect: false,
        options: customers.map((customer) => ({
          value: customer.text,
          name: customer.text,
          view: customer.text,
        })),
      },
    ],
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton href={NEW_VISIT_PATH}>Nowa wizyta</EuiButton>
        </EuiFlexItem>

        <EuiForm
          component="form"
          action="/visits/set_dates"
          method="get"
        >
          <EuiFlexGroup>
            <EuiFlexItem grow>
              <span style={{ width: "100px" }}>&nbsp;</span>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiFormRow label="Od">
                <EuiDatePicker
                  name="date_from"
                  showTimeSelect={false}
                  selected={dateFrom}
                  onChange={d => setDateFrom(d)}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  utcOffset={UTC_OFFSET}
                  locale="pl-pl"
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiFormRow label="Do">
                <EuiDatePicker
                  name="date_to"
                  showTimeSelect={false}
                  selected={dateTo}
                  onChange={d => setDateTo(d)}
                  dateFormat="DD-MM-YYYY"
                  timeFormat="HH:mm"
                  utcOffset={UTC_OFFSET}
                  locale="pl-pl"
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiFormRow label="&nbsp;">
                <EuiButton type="submit" fill>Szukaj</EuiButton>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiInMemoryTable
        items={visits}
        columns={columns}
        pagination={true}
        sorting={sorting}
        search={search}
      />

      {modalVisible && deleteModal}
    </>
  );
};

Index.propTypes = {
  visits: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  drivers: PropTypes.array,
  customers: PropTypes.array,
};

export default Index;
