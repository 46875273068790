import React, {useState} from "react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiInMemoryTable } from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import PropTypes from "prop-types";
import kind from "../customers/kind";

const DRIVERS_PATH = "/drivers/:id";
const NEW_DRIVER_PATH = "/drivers/new";

const Index = ({drivers}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [path, setPath] = useState("");

  const deleteModal = <DeleteModal
    path={path}
    description="Usuwam dane kierowcy z bazy danych."
    setModalVisible={setModalVisible}
  />;

  const destroyDriver = (driver) => {
    setPath(DRIVERS_PATH.replace(':id', `${driver.id}.json`));
    setModalVisible(true);
  };

  const columns = [
    {
      field: "id",
      name: "ID",
      sortable: true,
      truncateText: true,
    },
    {
      field: "first_name",
      name: "Imię",
      sortable: true,
      truncateText: true,
    },
    {
      field: "surname",
      name: "Nazwisko",
      sortable: true,
      truncateText: true,
    },
    {
      field: "active",
      name: "Aktywny?",
      dataType: "boolean",
      render: (active) => {
        const color = active ? "success" : "danger";
        return <EuiHealth color={color} />;
      },
      sortable: true,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja kierowcy",
          icon: "pencil",
          type: "icon",
          onClick: (driver) => {
            window.location.href = DRIVERS_PATH.replace(":id", `${driver.id}/edit`);
          },
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie kierowcy",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyDriver,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'surname',
      direction: 'asc',
    },
  };

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
    filters: [
      {
        type: 'is',
        field: 'active',
        name: 'Aktywny',
        negatedName: 'Nieaktywny',
      },
    ],
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton href={NEW_DRIVER_PATH}>Nowy kierowca</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiInMemoryTable
        items={drivers}
        columns={columns}
        pagination={true}
        sorting={sorting}
        search={search}
      />

      {modalVisible && deleteModal}
    </>
  );
};

Index.propTypes = {
  drivers: PropTypes.array
};

export default Index;
