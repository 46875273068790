import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { EuiFormRow, EuiDatePicker } from "@elastic/eui";
import moment from 'moment';
import { UTC_OFFSET } from "./tsFormSubmitHelpers";

const DateInput = ({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  label,
  helpText,
  fullWidth,
}) => {
  const initialDate = value ? moment.utc(value) : null;
  const [startDate, setStartDate] = useState(initialDate);

  const handleChange = (date) => {
    setStartDate(date);
    setFieldValue(name, date);
  };

  const fieldErrors = _.get(errors, name);
  const fieldTouched = _.get(touched, name);
  const isInvalid = fieldTouched && fieldErrors && fieldErrors.length > 0;

  return (
    <>
      <EuiFormRow
        label={label}
        fullWidth={fullWidth}
        isInvalid={isInvalid}
        error={fieldErrors}
        helpText={helpText}
      >
        <EuiDatePicker
          showTimeSelect={false}
          selected={startDate}
          onChange={handleChange}
          dateFormat="DD-MM-YYYY"
          timeFormat="HH:mm"
          utcOffset={UTC_OFFSET}
          locale="pl-pl"
        />
      </EuiFormRow>
    </>
  );
};

DateInput.propTypes = {
  // formik
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,

  label: PropTypes.string,
  helpText: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default DateInput;
