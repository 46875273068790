import React, { useState } from "react";
import PropTypes from "prop-types";
import { EuiContextMenuItem, EuiContextMenuPanel, EuiHeaderLink, EuiPopover } from "@elastic/eui";

const UserLink = ({ email, logoutPath }) => {
  const [isPopoverOpen, setPopover] = useState(false);

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiHeaderLink
      size="s"
      iconType="user"
      iconSide="left"
      onClick={onButtonClick}>
      {email}
    </EuiHeaderLink>
  );

  const items = [
    <EuiContextMenuItem
      key="Logout"
      onClick={() => {
        closePopover();
      }}>
      <EuiHeaderLink href={logoutPath} data-method="delete">
        Logout
      </EuiHeaderLink>
    </EuiContextMenuItem>,
  ];

  return (
    <EuiPopover
      id="singlePanel"
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft">
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};

UserLink.propTypes = {
  email: PropTypes.string,
  logoutPath: PropTypes.string,
};

export default UserLink;
