import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  EuiContextMenu,
  EuiHeaderLink,
  EuiIcon,
  EuiPopover
} from "@elastic/eui";

const NotificationsLink = ({ mainName }) => {
  const [isPopoverOpen, setPopover] = useState(false);

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiHeaderLink
      size="s"
      iconType="arrowDown"
      iconSide="right"
      onClick={onButtonClick}>
      {mainName}
    </EuiHeaderLink>
  );

  const panels = [
    {
      id: 0,
      items: [
        {
          name: "Nie osiągnęli limitów zakupowych",
          icon: <EuiIcon type="tableDensityNormal" size="m" />,
          onClick: () => {
            closePopover();
            window.location.href = "/notifications/limits";
          },
        },
        {
          name: "Mniejsze zakupy niż w poprzednim okresie",
          icon: <EuiIcon type="tableDensityNormal" size="m" />,
          onClick: () => {
            closePopover();
            window.location.href = "/notifications/previous_period";
          },
        },
        {
          name: "Pojazdy - nadchodzące przeglądy",
          icon: <EuiIcon type="visGauge" size="m" />,
          onClick: () => {
            closePopover();
            window.location.href = "/notifications/cars";
          },
        },
      ],
    },
  ];

  return (
    <EuiPopover
      id="singlePanel"
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft">
      <EuiContextMenu size="s" initialPanelId={0} panels={panels} />
    </EuiPopover>
  );
};

NotificationsLink.propTypes = {
  mainName: PropTypes.string,
};

export default NotificationsLink;
