import React from "react";
import { Form, Field } from 'formik';
import { EuiButton, EuiFlexGroup, EuiForm, EuiFormRow, EuiFlexItem } from "@elastic/eui";
import StringInput from "../fields/StringInput";
import PropTypes from "prop-types";

const CANCEL_PATH="/products";

// eslint-disable-next-line no-unused-vars
const ProductForm = ({ values, errors }) => {
  const isInvalid = errors.base && errors.base.length > 0;

  return (
    <Form>
      <EuiForm
        isInvalid={isInvalid}
        error={errors.base}
      >
        <Field
          name="name"
          placeholder="nazwa towaru"
          label="Nazwa"
          component={StringInput}
        />

        <Field
          name="base_price"
          placeholder="cena proponowana"
          label="Cena bazowa"
          component={StringInput}
        />

        <EuiFormRow>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton type="submit" fill disabled={isInvalid}>
                Submit
              </EuiButton>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton href={CANCEL_PATH}>
                Anuluj
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiForm>
    </Form>
  );
};

ProductForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
};

export default ProductForm;
