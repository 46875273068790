import React from "react";
import {
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiHeaderSectionItem,
} from "@elastic/eui";
import PropTypes from "prop-types";
import UserLink from "./UserLink";
import NotificationsLink from "./NotificationsLink";

const PageHeader = ({ logoutPath, email }) => {
  return (
    <EuiHeader>
      <EuiHeaderSectionItem border="right">
        <EuiHeaderLogo iconType="metricbeatApp">InteliGaz - Plus</EuiHeaderLogo>
      </EuiHeaderSectionItem>

      <EuiHeaderSectionItem>
        <EuiHeaderLinks aria-label="App navigation links example">
          <NotificationsLink mainName="Powiadomienia" />

          <EuiHeaderLink href="/drivers">Kadra</EuiHeaderLink>
          <EuiHeaderLink href="/products">Towary</EuiHeaderLink>
          <EuiHeaderLink href="/customers">Klienci</EuiHeaderLink>
          <EuiHeaderLink href="/visits">Sprzedaż</EuiHeaderLink>
          <EuiHeaderLink href="/cars">Flota</EuiHeaderLink>

          <UserLink email={email} logoutPath={logoutPath} />
        </EuiHeaderLinks>
      </EuiHeaderSectionItem>
    </EuiHeader>
  );
};

PageHeader.propTypes = {
  logoutPath: PropTypes.string,
  email: PropTypes.string,
};

export default PageHeader;
