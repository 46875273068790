import React from "react";
import { EuiFormRow, EuiCheckbox } from "@elastic/eui";
import _ from "lodash";

const Checkbox = ({
  field: { name, value, onChange },
  form: { errors },
  id,
  label,
  readOnly,
}) => {
  const fieldErrors = _.get(errors, name);
  const isInvalid = typeof fieldErrors !== "undefined";

  return (
    <EuiFormRow label={false} isInvalid={isInvalid} error={fieldErrors}>
      <EuiCheckbox
        label={label}
        compressed
        name={name}
        id={id}
        value={value}
        checked={value}
        onChange={onChange}
        disabled={readOnly}
      />
    </EuiFormRow>
  );
};

export default Checkbox;
