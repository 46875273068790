import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  EuiButton,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiInMemoryTable, EuiLink,
  EuiSpacer,
} from "@elastic/eui";
import { UTC_OFFSET } from "../fields/tsFormSubmitHelpers";
import moment from "moment";

const Limits = ({ customers, startDate, path }) => {
  const [dateFrom, setDateFrom] = useState(moment(startDate));

  const columns = [
    {
      field: "name",
      name: "Klient",
      sortable: true,
      truncateText: false,
    },
    {
      field: "should",
      name: "Powinien",
      sortable: true,
      truncateText: false,
      width: "6%",
    },
    {
      field: "purchased",
      name: "Kupił",
      sortable: true,
      truncateText: false,
      width: "5%",
    },
    {
      field: "not_enough",
      name: "Zabrakło",
      sortable: true,
      truncateText: false,
      width: "6%",
    },
    {
      name: "",
      width: "5%",
      actions: [
        {
          name: "Edycja",
          description: "Edycja tego klienta",
          type: "icon",
          icon: "pencil",
          isPrimary: true,
          onClick: (customer) => window.location.href = customer.path,
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  };

  const searchDate = (
    <EuiFlexGroup>
      <EuiForm
        component="form"
        action={path}
        method="get"
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Od">
              <EuiDatePicker
                name="date_from"
                showTimeSelect={false}
                selected={dateFrom}
                onChange={d => setDateFrom(d)}
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm"
                utcOffset={UTC_OFFSET}
                locale="pl-pl"
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFormRow label="&nbsp;">
              <EuiButton type="submit" fill>Szukaj</EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiFlexGroup>
  );

  return (
    <>
      {searchDate}

      <EuiSpacer />

      <EuiInMemoryTable
        items={customers}
        columns={columns}
        pagination={true}
        sorting={sorting}
      />
    </>
  );
};

Limits.propTypes = {
  customers: PropTypes.array,
  startDate: PropTypes.string,
  path: PropTypes.string,
};

export default Limits;
