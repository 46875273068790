import React from "react";
import { EuiConfirmModal } from "@elastic/eui";
import axios from "axios";
import PropTypes from "prop-types";

const DeleteModal = ({ path, description, setModalVisible }) => {
  const deleteAction = () => {
    setModalVisible(false);

    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    axios.delete(path, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      }
    })
      .then(function (response) {
        const url = response.data.url;
        if (url) {
          document.location.replace(response.data.url);
        } else {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Error: cannot delete");
      });
  };

  const closeModal = () => setModalVisible(false);

  return (
    <EuiConfirmModal
      title="Uwaga"
      onCancel={closeModal}
      onConfirm={deleteAction}
      cancelButtonText="Anuluj"
      confirmButtonText="Tak, jestem pewien"
      buttonColor="danger"
      defaultFocusedButton="confirm">
      <p>{description || "Usuwam rekord z bazy danych"}</p>
      <p>Czy jesteś pewien, że mam to zrobić?</p>
    </EuiConfirmModal>
  );
};

DeleteModal.propTypes = {
  path: PropTypes.string,
  description: PropTypes.string,
  setModalVisible: PropTypes.func
};

export default DeleteModal;
