import React, { useState, useEffect } from 'react';
import {
  EuiPage,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageSideBar,
  EuiPageBody, EuiButton,
} from '@elastic/eui';

const Home  = () => {
  const loginBtn = <EuiButton>Login</EuiButton>;
  const content = <span>ala ma kota</span>;

  return (
    <EuiPage paddingSize="none">
      <EuiPageBody panelled>
        <EuiPageHeader
          restrictWidth
          iconType="logoElastic"
          pageTitle="Page title"
          rightSideItems={[loginBtn]}
          tabs={[{ label: 'Tab 1', isSelected: true }, { label: 'Tab 2' }]}
        />
        <EuiPageContent
          hasBorder={false}
          hasShadow={false}
          paddingSize="none"
          color="transparent"
          borderRadius="none">
          <EuiPageContentBody restrictWidth>{content}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
};

export default Home;
