const errorsContains = (err, searchedString) => {
  return Object.keys(err).filter(key => key.includes(searchedString));
}

const validateAmount = (value) => {
  let error;
  if(!value || value < 1) {
    error = "Musi być większa od zera!";
  }
  return error
};

export {errorsContains, validateAmount};
