import React from "react";
import { Formik } from 'formik';
import VisitForm from "./VisitForm";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";
import PropTypes from "prop-types";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import _ from "lodash";

const Edit = ({ visit, drivers, customers, products }) => {
  const isNewRecord = !visit.id;
  let path;
  if (isNewRecord) {
    path = "/visits.json";
    visit = {...visit, date_of_v: ""};
  } else {
    path = `/visits/${visit.id}.json`;
  }

  const handleSubmitResource2 = ({
    path,
    isNewRecord,
    omitData,
  }) => (
    values,
    { setSubmitting, setErrors }
  ) => {

    const valuesToSend = omitData ? _.omit(values, omitData) : values;

    console.log(valuesToSend);

    /*
    submitForm(valuesToSend, path)
      .then((resolve) => {
        if (resolve.url) {
          window.location.href = resolve.url;
        } else {
          console.log(resolve);
        }
      })
      .catch((errors) => {
        setErrors(errors);
      });
    setSubmitting(false);
    */
  };


  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <Formik
          initialValues={visit}
          onSubmit={handleSubmitResource({ path, isNewRecord })}
        >
          {({ values, errors }) => (
            <VisitForm
              values={values}
              errors={errors}
              drivers={drivers}
              customers={customers}
              products={products}
            />
          )}
        </Formik>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

Edit.propTypes = {
  visit: PropTypes.object,
  drivers: PropTypes.array,
  customers: PropTypes.array,
  products: PropTypes.array,
};

export default Edit;
