import React from "react";
import { EuiButton, EuiForm, EuiFormRow } from "@elastic/eui";
import { Field, Form } from "formik";
import PropTypes from "prop-types";
import SelectInput from "../fields/SelectInput";
import StringInput from "../fields/StringInput";
import NumberInput from "../fields/NumberInput";

// eslint-disable-next-line no-unused-vars
const EstateForm = ({values, errors, products}) => {
  const isInvalid = errors.base && errors.base.length > 0;

  return (
    <Form>
      <EuiForm
        isInvalid={isInvalid}
        error={errors.base}
      >
        <Field
          name="product_id"
          label="Towar"
          component={SelectInput}
          options={products}
        />

        <Field
          name="amount"
          label="Ilość"
          component={NumberInput}
        />

        <Field
          name="description"
          placeholder="uwagi"
          label="Uwagi"
          component={StringInput}
        />

        <EuiFormRow>
          <EuiButton type="submit" fill>Zapisz</EuiButton>
        </EuiFormRow>
      </EuiForm>
    </Form>
  );
};

EstateForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  products: PropTypes.array,
};

export default EstateForm;
