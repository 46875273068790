import React, { useState } from "react";
import PropTypes from "prop-types";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiText, EuiTitle } from "@elastic/eui";
import DeleteModal from "../DeleteModal";
import EstateEditModal from "./EstateEditModal";

const estateBlank = {
  customer_id: "",
  product_id: "",
  amount: "",
  description: "",
};

const DELETE_ESTATE_PATH = "customer_estates/:id";

const EstatesEdit = ({ customer_estates, products }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [path, setPath] = useState("");
  const [estate, setEstate] = useState(estateBlank);

  const deleteModal = (<DeleteModal
    path={path}
    description="Usuwam dane majątku z kartoteki klienta."
    setModalVisible={setDeleteModalVisible}
  />);

  const destroyEstate = (est) => {
    setPath(DELETE_ESTATE_PATH.replace(':id', `${est.id}.json`));
    setDeleteModalVisible(true);
  }

  const editEstate = (est) => {
    setEstate(est);
    setModalVisible(true);
  };

  const columns = [
    {
      field: "product_id",
      name: "Towar w posiadaniu",
      sortable: false,
      truncateText: true,
      render: (id) => {
        const product = products.find(st => st.value === id);
        return <EuiText>{product.text}</EuiText>;
      },
    },
    {
      field: "amount",
      name: "Ilość",
      sortable: false,
      truncateText: false,
    },
    {
      field: "description",
      name: "Uwagi",
      sortable: false,
      truncateText: true,
    },
    {
      name: "Edycja",
      actions: [
        {
          name: "Edytuj",
          isPrimary: true,
          description: "Edycja towaru",
          icon: "pencil",
          type: "icon",
          onClick: editEstate,
          "data-test-subj": "action-edit",
        },
        {
          name: "Usuń",
          description: "Usuwanie towaru",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: destroyEstate,
          isPrimary: false,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const sorting = {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  };

  return (
    <>
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiTitle size="s">
            <h4>Majątek klienta</h4>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiText>
            <EuiButton onClick={() => setModalVisible(true)}>Nowy</EuiButton>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiInMemoryTable
            items={customer_estates}
            columns={columns}
            pagination={true}
            sorting={sorting}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      {
        modalVisible
        &&
        <EstateEditModal
          estate={estate}
          products={products}
          setModalVisible={setModalVisible}
        />
      }

      {deleteModalVisible && deleteModal}
    </>
  );
};

EstatesEdit.propTypes = {
  customer_estates: PropTypes.array,
  products: PropTypes.array,
};

export default EstatesEdit;
