/* eslint-disable react/prop-types */
import React from "react";
import _ from "lodash";

import { EuiFormRow, EuiFieldNumber } from "@elastic/eui";

const NumberInput = ({
  field: { name, value },
  form: { errors, setFieldValue },
  label,
  readOnly,
  append,
  helpText,
  isFloat,
}) => {
  const sanitizeOnChange = (e) => {
    if (isFloat) {
      setFieldValue(name, e.target.value);
    } else {
      const sanitizedValue = parseInt(e.target.value, 10);
      setFieldValue(name, sanitizedValue);
    }
  };

  const fieldErrors = _.get(errors, name);
  const isInvalid = fieldErrors && fieldErrors.length > 0;
  return (
    <>
      <EuiFormRow
        label={label}
        isInvalid={isInvalid}
        error={fieldErrors}
        helpText={helpText}
      >
        <EuiFieldNumber
          name={name}
          placeholder="0"
          value={value}
          onChange={sanitizeOnChange}
          isInvalid={isInvalid}
          readOnly={readOnly}
          append={append}
        />
      </EuiFormRow>
    </>
  );
};

export default NumberInput;
