import React from "react";
import { EuiButton, EuiForm, EuiFormRow } from "@elastic/eui";
import { Field, Form } from "formik";
import StringInput from "../fields/StringInput";
import PropTypes from "prop-types";
import DateInput from "../fields/DateInput";
import SelectInput from "../fields/SelectInput";

// eslint-disable-next-line no-unused-vars
const ServicingForm = ({values, errors, servicing_types}) => {
  const isInvalid = errors.base && errors.base.length > 0;

  return (
    <Form>
      <EuiForm
        isInvalid={isInvalid}
        error={errors.base}
      >
        <Field
          name="servicing_type_id"
          label="Typ"
          component={SelectInput}
          options={servicing_types}
        />

        <Field
          name="date_done"
          label="Data wpisu"
          component={DateInput}
        />

        <Field
          name="date_next"
          label="Data następnego"
          component={DateInput}
        />

        <Field
          name="date_alarm"
          label="Data alarmu"
          component={DateInput}
        />

        <Field
          name="description"
          placeholder="uwagi"
          label="Uwagi"
          component={StringInput}
        />

        <EuiFormRow>
          <EuiButton type="submit" fill>Zapisz</EuiButton>
        </EuiFormRow>
      </EuiForm>
    </Form>
  );
};

ServicingForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  servicing_types: PropTypes.array,
};

export default ServicingForm;
