import React from "react";
import { Formik } from 'formik';
import DriverForm from "./DriverForm";
import { handleSubmitResource } from "../fields/tsFormSubmitHelpers";

const Edit = ({ driver }) => {
  const isNewRecord = !driver.id;
  let path;
  if (isNewRecord) {
    path = "/drivers.json";
  } else {
    path = `/drivers/${driver.id}.json`;
  }

  return (
    <Formik
      initialValues={driver}
      onSubmit={handleSubmitResource({path, isNewRecord})}
    >
      {({ values, errors}) => (
        <DriverForm values={values} errors={errors} />
      )}
    </Formik>
  );
};

export default Edit;
